import amraiahmetphoto from "../media/images/amraiahmet.jpg";
import dzenita from "../media/images/dzenita.jpg";
import medinaimirza from "../media/images/medinaimirza.jpg";

function clients() {

    const clients = [
        {
            "id": 1,
            "name": "simona",
            "url":"https://www.playbook.com/s/infinityweddings/gtuqik3xpGpz2A9e8i4QgNgK",
            "title":"Vjenčanje Simona",
            "password": "simona2024"
        },

        {
            "id": 2,
            "name": "irmaidario",
            "url":"https://www.playbook.com/s/infinityweddings/jv9pugG424MhZiaxbPH5Lcnz",
            "title":"Vjenčanje Irma & Dario",
            "password": "irmaidario2024"
        },

        {
            "id": 3,
            "name": "amraiahmet",
            "url":"https://www.playbook.com/s/infinityweddings/1V6N2YhByGqreznsn4cwdxXh",
            "gostiUrl": "https://www.playbook.com/s/infinityweddings/g9924ZXhLEippkZkqa9ar5aZ",
            "title":"Vjenčanje Amra i Ahmet",
            "password": "amraiahmet2024",
            "gostiPassword": "gostiaia",
            "image": amraiahmetphoto
        },
        {
            "id": 4,
            "name": "dzenita",
            "url":"https://www.playbook.com/s/infinityweddings/Kgyvy6ddttgTh4pPu1BV9uMw",
            "gostiUrl": "",
            "title":"Dženita Pregnancy Photoshoot",
            "password": "dzenita2024",
            "gostiPassword": "",
            "image": dzenita
        },
        {
            "id": 5,
            "name": "maratontz",
            "url":"https://www.playbook.com/s/infinitycreativestudio/7UaWdmzxLc2S3SNP24Eg9dkA",
            "gostiUrl": "",
            "title":"Tuzlanski Maraton 2024",
            "redirect":"true",
            "password": "",
            "gostiPassword": "",
            "image": dzenita
        },
        {
            "id": 6,
            "name": "medinaimirza",
            "url":"https://www.playbook.com/s/infinityweddings/MKkWSCGGgM9iXGGwNwCWz8qU",
            "gostiUrl": "https://www.playbook.com/s/infinityweddings/6fZpW8gnqG3N74zmyEn1DJFn",
            "title":"Vjenčanje Medina i Mirza",
            "redirect":"false",
            "password": "mim2024",
            "gostiPassword": "medinaimirza",
            "image": medinaimirza
        },


    ];

    return clients;
}

export default clients;